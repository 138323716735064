.trip-view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 80px;
  width: fit-content;
  line-height: 300%;
  margin-left: 12px;

  &-text {
    font-size: 34px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 160%;
  }
  &-link {
    font-size: 20px;
    text-decoration: underline;
    text-underline-offset: 3px;
    font-weight: 500;
    color: $primary-color;
  }
  &-status {
    margin-left: 8px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;

    &.gray {
      background-color: $status-background-draft;
      color: $primary-color;
    }
    &.blue {
      background-color: $status-background-submitted;
      color: $input-color-light;
    }
  }
  &-button {
    margin-top: 40px;
    background: $button-add-color;
    color: $input-color-light;

    box-shadow: 0px 0px 2px rgba(233, 37, 37, 0.12);
    border-radius: 4px;
    border: 0px;
    height: fit-content;
    margin-bottom: 60px;
  }
}

@include responsiveScreenWidth(600px) {
  .trip-view {
    padding: 40px;
    &-nowrap {
      white-space: nowrap;
    }
  }
}

@include responsiveScreenWidth(391px) {
  .trip-view {
    padding: 15px;
    width: 90%;
    &-text {
      font-size: 20px;
    }
  }
}
