@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.landing-page {
  font-family: 'Rubik', sans-serif;
  background: url('/media/landing-background.svg') no-repeat bottom right;
  background-size: contain;
  height: 100vh;
  max-width: 100%;
  @extend .padding-xl;

  &-text-and-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50vh;
    &-title-subtitle {
      max-width: 784px;
      margin-bottom: 40px;

      &-accent {
        color: $accent-color;
        white-space: nowrap;
      }

      h1 {
        @extend .padding-y-sm;
        text-align: left;
      }
      p {
        font-size: 18px;
        color: $secondary-color;
        text-align: justify;
        @extend .padding-y-sm;
        line-height: 24px;
      }
    }
  }
}
.google-signin {
  width: fit-content;
}

@include responsiveScreenWidth(808px) {
  .landing-page {
    background-size: 808px;
  }
}

@include responsiveScreenWidth(400px) {
  .landing-page {
    background-size: 600px;

    &-text-and-button {
      &-title-subtitle {
        h1 {
          font-size: 34px;
          letter-spacing: 0.25px;
          line-height: 42px;
        }
        p {
          font-size: 14px;
          text-align: left;
          letter-spacing: 0.15px;
          line-height: 20px;
        }
      }
    }
  }
}

@media only screen and (max-height: 630px) {
  .landing-page {
    background-size: 630px;
  }
}

@media only screen and (max-height: 400px) {
  .landing-page {
    background-size: 550px;
    &-text-and-button {
      &-title-subtitle {
        h1 {
          font-size: 40px;
          letter-spacing: 0.25px;
          line-height: 42px;
        }
        p {
          font-size: 15px;
          text-align: left;
          letter-spacing: 0.15px;
          line-height: 20px;
          width: 60vw;
        }
      }
    }
  }
}

@media only screen and (max-height: 300px) {
  .landing-page {
    background-size: 420px;

    &-text-and-button {
      &-title-subtitle {
        h1 {
          font-size: 34px;
          letter-spacing: 0.25px;
          line-height: 42px;
        }
        p {
          font-size: 14px;
          text-align: left;
          letter-spacing: 0.15px;
          line-height: 20px;
          width: 60vw;
        }
      }
    }
  }
}
