.frame {
  @include flexColumn;

  &-text {
    text-align: center;
    font-size: 34px;
    letter-spacing: 0.25px;
    @extend .padding-l;
    margin: 0px 24px;
  }

  &-box {
    @include flexColumn;
    padding: 48px 80px;
    margin: 20px;
    margin-bottom: 60px;
    background: $form-box-color;
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  }
}
.form {
  &-input {
    @extend .padding-input;

    width: 220px;
    height: 40px;
    font-size: 16px;

    border: 2px solid $input-border-color;
    border-radius: 4px;
    background: $input-background-color;

    &-borderless {
      @extend .padding-input;

      width: 220px;
      height: 40px;

      border-radius: 4px;
    }
    &-full {
      @extend .padding-input;
      height: 40px;
      width: 456px;
      font-size: 16px;

      border: 2px solid $input-border-color;
      border-radius: 4px;
      background: $input-background-color;
    }
  }
  &-type-label {
    letter-spacing: 0.1px;
    display: flex;
    font-weight: 600;
    margin-bottom: 8px;
  }
}
.result-label {
  font-size: 17px;
  padding: 5px;
}
.nowrap {
  white-space: nowrap;
}

@include responsiveScreenWidth(856px) {
  .frame {
    &-box {
      padding: 40px;
      margin: 40px;
      margin-bottom: 80px;
    }
  }
}
@include responsiveScreenWidth(624px) {
  .frame {
    &-text {
      font-size: 30px;
    }
    &-box {
      width: min-content;
      padding: 30px 40px;
      margin: 40px;
      margin-bottom: 80px;
    }
  }
}
@include responsiveScreenWidth(460px) {
  .frame {
    &-text {
      padding: 0px;
      font-size: 24px;
    }
    &-box {
      width: min-content;
      padding: 20px 30px;
      margin-bottom: 80px;
    }
  }
  .nowrap {
    white-space: normal;
  }
}
@include responsiveScreenWidth(338px) {
  .frame {
    &-box {
      width: min-content;
      padding: 15px;
    }
  }
}

#employmentLocationId_list > div,
#startAirportId_list > div,
#finalLocationId_list > div {
  display: block;
}
