$primary-color: #272727;
$secondary-color: #666;
$accent-color: #ff1b3a;
$input-color-light: #fff;

$background-color: #fafdff;
$header-color: #e0e0e0;
$footer-color: #9e9e9e;
$form-box-color: #eee;

$button-add-color: #ff1b3a;
$button-submit-color: #3f51b5;

$input-border-color: #7986cb;
$input-background-color: #fff;

$status-background-draft: #cccccc;
$status-background-submitted: #3f51b5;

$disabled-border-color: #d9d9d9;
