@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import 'colors';
@import 'spacing';
@import 'config';
@import 'antd-overrides.scss';
@import '../components/layout/header';
@import '../components/layout/footer';
@import '../pages/FlightForm/FlightForm';
@import '../pages/FlightHistory/flightHistory';
@import '../pages/LandingPage/landing-page';

body {
  background-color: $background-color;
  position: relative;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 60px;
  font-weight: 600;
  color: $primary-color;
}

h2,
h3,
p {
  font-weight: 400;
  color: $primary-color;
}
