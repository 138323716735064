header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: relative;
  width: 100%;
  height: 56px;
  padding: 0 10%;
  margin-bottom: 48px;

  background: $header-color;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);

  .logo-text {
    font-size: 24px;
    line-height: 133.4%;
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }
  .combined {
    display: flex;
    flex-direction: row;
  }
}
