.ant-select .ant-select-selector,
.ant-picker .ant-picker-input input,
.ant-picker .ant-picker-suffix {
  margin: -2px;
  font-size: 16px;
  font-weight: 400;
}

.ant-select .ant-select-selector {
  margin: -9px;
  margin-left: -12px;
  margin-right: -12px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  border: 2px solid $input-border-color;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 2px solid $disabled-border-color;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 220px;
  height: 40px;
  padding-top: 4px;
  border-radius: 4px;
}

.ant-form {
  padding-bottom: 20px;
}

.ant-btn-primary.ant-btn-dangerous {
  background-color: $accent-color;
}
