* {
  box-sizing: border-box;
  margin: 0;
  @extend .padding-0;
}
.wrapper {
  min-height: 100vh;
  position: relative;
}

@mixin flexColumn {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin responsiveScreenWidth($screen-width) {
  @media only screen and (max-width: $screen-width) {
    @content;
  }
}
