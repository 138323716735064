$s-size: 4px;
$sm-size: 8px;
$m-size: 12px;
$l-size: 20px;
$xl-size: 40px;

.padding-input {
  padding: 9px 12px;
}
.padding-0 {
  padding: 0px;
}
.padding-sm {
  padding: $sm-size;
}
.padding-l {
  padding: $m-size;
}
.padding-xl {
  padding: $xl-size;
}

.padding-x-s {
  padding-left: $s-size;
  padding-right: $s-size;
}
.padding-y-s {
  padding-top: $s-size;
  padding-bottom: $s-size;
}
.padding-x-sm {
  padding-left: $sm-size;
  padding-right: $sm-size;
}
.padding-y-sm {
  padding-top: $sm-size;
  padding-bottom: $sm-size;
}
.padding-x-m {
  padding-left: $m-size;
  padding-right: $m-size;
}
.padding-y-m {
  padding-top: $m-size;
  padding-bottom: $m-size;
}
.padding-x-l {
  padding-left: $l-size;
  padding-right: $l-size;
}
.padding-y-l {
  padding-top: $l-size;
  padding-bottom: $l-size;
}
.padding-x-xl {
  padding-left: $xl-size;
  padding-right: $xl-size;
}
.padding-y-xl {
  padding-top: $xl-size;
  padding-bottom: $xl-size;
}

.margin-y-s {
  margin-top: $s-size;
  margin-bottom: $s-size;
}
.margin-y-sm {
  margin-top: $sm-size;
  margin-bottom: $sm-size;
}
.margin-y-m {
  margin-top: $m-size;
  margin-bottom: $m-size;
}
.margin-y-l {
  margin-top: $l-size;
  margin-bottom: $l-size;
}
.margin-y-xl {
  margin-top: $xl-size;
  margin-bottom: $xl-size;
}

.margin-x-s {
  margin-right: $s-size;
  margin-left: $s-size;
}
.margin-x-sm {
  margin-right: $sm-size;
  margin-left: $sm-size;
}
.margin-x-m {
  margin-right: $m-size;
  margin-left: $m-size;
}
.margin-x-l {
  margin-right: $l-size;
  margin-left: $l-size;
}
.margin-x-xl {
  margin-right: $xl-size;
  margin-left: $xl-size;
}
