footer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  height: 2.5rem;
  bottom: 0;
  background: $footer-color;

  .footer-text {
    @extend .padding-x-xl;
    font-size: 12px;
    line-height: 166%;

    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
  }
}

@media only screen and (max-width: 680px) {
  footer {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 400px) {
  footer {
    .footer-text {
      padding: 0px 20px;
    }
  }
}
